import React, { useEffect } from "react";
import '../../App.css';
import { Helmet } from "react-helmet-async";
import Images from "../../components/Images";
import { auth, storage, database } from "../../firebase";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { ref, child, get, update } from "firebase/database";
import { ref as refStorage ,uploadBytesResumable, getDownloadURL } from "firebase/storage";
import DashboardHeader from "../../components/DashboardHeader";
import Footer from "../../components/Footer";
import Compressor from "compressorjs"
import Toast from "../../components/Toast";

const Dashboard = () => {
    useEffect(() => {
        const navButton = document.getElementById("nav-button");
        const navMobile = document.getElementById("navigation-mobile");
        const keluarHeader = document.getElementById("keluar-header");
      
        const loading = document.getElementById("loading");
        const userInfo = document.getElementById("user-info");
        const photoProfile = document.getElementById("photo-profile");
        const usernameText = document.getElementById("username");
        const emailText = document.getElementById("email");
      
        const unsubscribe = onAuthStateChanged(auth, (user) => {
          if (!user) {
            window.location = "/login/";
          } else {
            getUserInfoData(user);
            getUserTokoInfoData(user);
      
            const photoProfileTokoEdit = document.getElementById("photo-profile-toko-edit");
            const choosePicture = document.getElementById("choosePicture");
      
            const handlePhotoProfileTokoEditClick = () => {
              choosePicture.click();
            };
      
            const handleChoosePictureChange = () => {
              const file = choosePicture.files[0];
      
              if (file) {
                const options = {
                  quality: 0.6,
                  maxWidth: 800,
                  maxHeight: 800,
                  mimeType: 'image/jpeg',
                  success(result) {
                    uploadGambar(result, user);
                  },
                  error(err) {
                    const message = "Error mengompresi gambar: " + err;
                    handleError(message);
                  },
                };
      
                new Compressor(file, options);
              } else {
                const message = "Tidak ada Berkas yang diseleksi";
                handleError(message);
              }
            };
      
            photoProfileTokoEdit.addEventListener('click', handlePhotoProfileTokoEditClick);
            choosePicture.addEventListener('change', handleChoosePictureChange);
          }
        });
      
        const handleKeluarHeaderClick = () => {
          signOut(auth)
            .then(() => {
              window.location = "/";
            })
            .catch((error) => {
              const message = error.code + ": " + error.message;
              handleError(message);
            });
        };
      
        keluarHeader.addEventListener('click', handleKeluarHeaderClick);
      
        const getUserInfoData = (user) => {
          const dbRef = ref(database);
          get(child(dbRef, `users/${user.uid}`))
            .then((snapshot) => {
              if (snapshot.exists()) {
                loading.style.display = "none";
                userInfo.style.display = "flex";
      
                photoProfile.src = user.photoURL;
                usernameText.innerText = snapshot.val().username;
                emailText.innerText = snapshot.val().email;
              } else {
                const message = "Data User Tidak Terbaca";
                handleError(message);
              }
            })
            .catch((error) => {
              const message = error.code + ": " + error.message;
              handleError(message);
            });
        };
      
        const logoToko = document.getElementById("logo-toko");
        const dataTokoLoading = document.getElementById("data-toko-loading");
        const infoTeksToko = document.getElementById("info-text-toko");
        const namaTokoEdit = document.getElementById("nama-toko-edit");
        const sloganTokoEdit = document.getElementById("slogan-toko-edit");
        const urlTokoEdit = document.getElementById("url-toko-edit");
        const emailTokoEdit = document.getElementById("email-toko-edit");
        const instagramTokoEdit = document.getElementById("instagram-toko-edit");
        const facebookTokoEdit = document.getElementById("facebook-toko-edit");
        const twitterTokoEdit = document.getElementById("twitter-toko-edit");
        const whatsappTokoEdit = document.getElementById("whatsapp-toko-edit");
        const mainDashboard = document.getElementById("dashboard-main");
        const saveData = document.getElementById("saveData");
        const chatUs = document.getElementById("chatUs");
      
        // Toast Element
        const ST = document.getElementById("success-toast");
        const ET = document.getElementById("error-toast");
        const STT = document.getElementById("success-title-toast");
        const SMT = document.getElementById("success-message-toast");
        const ETT = document.getElementById("error-title-toast");
        const EMT = document.getElementById("error-message-toast");
      
        // Creative-Breafing
        const projectBackground = document.getElementById("project-background");
        const productKnowledge = document.getElementById("product-knowledge");
        const objective = document.getElementById("objective");
        const targetAudience = document.getElementById("target-audience");
        const keyMessage = document.getElementById("message-key");
        const designToneAndManner = document.getElementById("design-tone-and-manner");
        const copywritingRequired = document.getElementById("copywriting-required");
        const mandatoryElement = document.getElementById("mandatory-element");
        const mediaDetails = document.getElementById("media-details");
        const confusedButton = document.getElementById("confused");
      
        const handleError = (message) => {
          window.location = '#error-toast';
          ET.style.display = "flex";
          EMT.innerText = message;
          setTimeout(() => {
            ET.style.display = "none";
          }, 3000);
        };
      
        const handleSuccess = (message) => {
          window.location = '#success-toast';
          ST.style.display = "flex";
          SMT.innerText = message;
          setTimeout(() => {
            ST.style.display = "none";
          }, 3000);
        };
      
        const handleSaveData = () => {
          const user = auth.currentUser;
      
          const namaTokoUpdate = namaTokoEdit.value;
          const sloganTokoUpdate = sloganTokoEdit.value;
          const emailTokoUpdate = emailTokoEdit.value;
          const urlTokoUpdate = urlTokoEdit.value;
          const instagramTokoUpdate = instagramTokoEdit.value;
          const facebookTokoUpdate = facebookTokoEdit.value;
          const twitterTokoUpdate = twitterTokoEdit.value;
          const whatsappTokoUpdate = normalizePhoneNumber(whatsappTokoEdit.value);
          const projectBackgroundUpdate = projectBackground.value;
          const productKnowledgeUpdate = productKnowledge.value;
          const objectiveUpdate = objective.value;
          const targetAudienceUpdate = targetAudience.value;
          const keyMessageUpdate = keyMessage.value;
          const designToneAndMannerUpdate = designToneAndManner.value;
          const copywritingRequiredUpdate = copywritingRequired.value;
          const mandatoryElementUpdate = mandatoryElement.value;
          const mediaDetailsUpdate = mediaDetails.value;
      
          saveDataToko(user, namaTokoUpdate, sloganTokoUpdate, emailTokoUpdate, urlTokoUpdate, instagramTokoUpdate, facebookTokoUpdate, twitterTokoUpdate, whatsappTokoUpdate, projectBackgroundUpdate, productKnowledgeUpdate, objectiveUpdate, targetAudienceUpdate, keyMessageUpdate, designToneAndMannerUpdate, copywritingRequiredUpdate, mandatoryElementUpdate, mediaDetailsUpdate);
        };
      
        saveData.addEventListener('click', handleSaveData);
      
        const saveDataToko = (user, namaTokoUpdate, sloganTokoUpdate, emailTokoUpdate, urlTokoUpdate, instagramTokoUpdate, facebookTokoUpdate, twitterTokoUpdate, whatsappTokoUpdate, projectBackgroundUpdate, productKnowledgeUpdate, objectiveUpdate, targetAudienceUpdate, keyMessageUpdate, designToneAndMannerUpdate, copywritingRequiredUpdate, mandatoryElementUpdate, mediaDetailsUpdate) => {
          const dataToko = ref(database, `users/${user.uid}/datatoko/`);
          const dataMediaSosialToko = ref(database, `users/${user.uid}/datatoko/mediasosial/`);
          const dataCreativeBreafing = ref(database, `users/${user.uid}/datatoko/creative-breafing/`);

          update(dataToko, {
            namatoko: namaTokoUpdate,
            slogantoko: sloganTokoUpdate,
            emailtoko: emailTokoUpdate,
            urltoko: "https://" + urlTokoUpdate + ".web.app",
          })
            .then(() => {
              update(dataMediaSosialToko, {
                instagram: instagramTokoUpdate,
                facebook: facebookTokoUpdate,
                twitter: twitterTokoUpdate,
                whatsapp: whatsappTokoUpdate,
              })
                .then(() => {
                  update(dataCreativeBreafing, {
                    projectBackground: projectBackgroundUpdate,
                    productKnowledge: productKnowledgeUpdate,
                    objective: objectiveUpdate,
                    targetAudience: targetAudienceUpdate,
                    keyMessage: keyMessageUpdate,
                    designToneAndManner: designToneAndMannerUpdate,
                    copywritingRequired: copywritingRequiredUpdate,
                    mandatoryElement: mandatoryElementUpdate,
                    mediaDetails: mediaDetailsUpdate,
                  })
                    .then(() => {
                      const message = "Berhasil Update Data Toko";
                      handleSuccess(message);
                    })
                    .catch((error) => {
                      const message = error.code + ": " + error.message;
                      handleError(message);
                    });
                })
                .catch((error) => {
                  const message = error.code + ": " + error.message;
                  handleError(message);
                });
            })
            .catch((error) => {
              const message = error.code + ": " + error.message;
              handleError(message);
            });
          };
          
          const handleChatUs = () => {
            const user = auth.currentUser;
          
            sendMessageText(user);
          };
          
          chatUs.addEventListener('click', handleChatUs);
          
          const sendMessageText = (user) => {
            const dbRef = ref(database);
            get(child(dbRef, `users/${user.uid}/`))
              .then((datauser) => {
                if (datauser.exists()) {
                  const namaUser = datauser.val().username;
                  const photoPrefix = datauser.val().photoURL;
                  const photoURL = "https://firebasestorage.googleapis.com/v0/b/project-tokoonlinemu.appspot.com/o/" + encodeURIComponent(photoPrefix.substring(photoPrefix.lastIndexOf("/") + 1));
                  const emailUser = datauser.val().email;
          
                  get(child(dbRef, `users/${user.uid}/datatoko`))
                    .then((snapshot) => {
                      window.open(`https://api.whatsapp.com/send?phone=62895326373394&text=Hai, saya dengan ID Toko ${user.uid}, dan Nama Pengguna ${namaUser} Ingin mengajak berkolaborasi *tokoonlinemu.* untuk membuat website kami!`, '_blank');
                    })
                    .catch((error) => {
                      const message = error.code + ": " + error.message;
                      handleError(message);
                    });
                } else {
                  const message = "Data User Tidak Terbaca";
                  handleError(message);
                }
              })
              .catch((error) => {
                const message = error.code + ": " + error.message;
                handleError(message);
              });
          };
          
          const getUserTokoInfoData = (user) => {
            const dbRef = ref(database);
            get(child(dbRef, `users/${user.uid}/datatoko`))
              .then((snapshot) => {
                if (snapshot.exists()) {
                  const namatoko = snapshot.val().namatoko;
                  const slogantoko = snapshot.val().slogantoko;
                  const emailtoko = snapshot.val().emailtoko;
                  const logotoko = snapshot.val().logotoko;
                  const urltoko = snapshot.val().urltoko;
                  const instagramtoko = snapshot.val().mediasosial.instagram;
                  const facebooktoko = snapshot.val().mediasosial.facebook;
                  const twittertoko = snapshot.val().mediasosial.twitter;
                  const whatsapptoko = snapshot.val().mediasosial.whatsapp;
                  const projectBackgrounddb = snapshot.val()["creative-breafing"].projectBackground;
                  const productKnowledgedb = snapshot.val()["creative-breafing"].productKnowledge;
                  const objectivedb = snapshot.val()["creative-breafing"].objective;
                  const targetAudiencedb = snapshot.val()["creative-breafing"].targetAudience;
                  const keyMessagedb = snapshot.val()["creative-breafing"].keyMessage;
                  const designToneAndMannerdb = snapshot.val()["creative-breafing"].designToneAndManner;
                  const copywritingRequireddb = snapshot.val()["creative-breafing"].copywritingRequired;
                  const mandatoryElementdb = snapshot.val()["creative-breafing"].mandatoryElement;
                  const mediaDetailsdb = snapshot.val()["creative-breafing"].mediaDetails;
          
                  dataTokoLoading.style.display = "none";
                  mainDashboard.style.height = "100%";
          
                  logoToko.src = logotoko;
                  namaTokoEdit.value = namatoko;
                  sloganTokoEdit.value = slogantoko;
                  emailTokoEdit.value = emailtoko;
                  urlTokoEdit.value = urltoko.replace(/^https?:\/\/(.+)\.web\.app$/, '$1');
                  instagramTokoEdit.value = instagramtoko;
                  facebookTokoEdit.value = facebooktoko;
                  twitterTokoEdit.value = twittertoko;
                  whatsappTokoEdit.value = whatsapptoko;
                  projectBackground.value = projectBackgrounddb;
                  productKnowledge.value = productKnowledgedb;
                  objective.value = objectivedb;
                  targetAudience.value = targetAudiencedb;
                  keyMessage.value = keyMessagedb;
                  designToneAndManner.value = designToneAndMannerdb;
                  copywritingRequired.value = copywritingRequireddb;
                  mandatoryElement.value = mandatoryElementdb;
                  mediaDetails.value = mediaDetailsdb;
          
                  infoTeksToko.style.display = "flex";
                } else {
                  const message = "Data tidak ada, coba Daftar Ulang untuk Mengatasinya";
                  handleError(message);
                }
              })
              .catch((error) => {
                const message = error.code + ": " + error.message;
                handleError(message);
              });
          };
          
          const uploadGambar = (file, user) => {
            const storageRef = refStorage(storage, `users/${user.uid}/` + 'logo.png');
            const uploadTask = uploadBytesResumable(storageRef, file);
          
            uploadTask.on('state_changed', () => {}, (error) => {
              const message = "Error Mengunggah Gambar:  " + error;
              handleError(message);
            }, () => {
              getDownloadURL(uploadTask.snapshot.ref)
                .then((downloadURL) => {
                  const userRef = ref(database, `users/${user.uid}/datatoko/`);
                  update(userRef, {
                    logotoko: downloadURL,
                  })
                    .then(() => {
                      const message = "Logo toko telah diupdate";
                      handleSuccess(message);
                    })
                    .catch((error) => {
                      const message = error.code + ": " + error.message;
                      handleError(message);
                    });
                })
                .catch((error) => {
                  const message = error.code + ": " + error.message;
                  handleError(message);
                });
            });
          };
          
          const handleUrlTokoEditInput = () => {
            const inputValue = urlTokoEdit.value;
            const sanitizedValue = validateInput(inputValue);
            urlTokoEdit.value = sanitizedValue;
            };
            
            const validateInput = (input) => {
              return input.replace(/[^a-zA-Z0-9]/g, '');
            };
            
            urlTokoEdit.addEventListener('input', handleUrlTokoEditInput);
            
            const handleConfusedButtonClick = () => {
              const user = auth.currentUser;
            
              if (user) {
                const userId = user.uid;
                window.open(`https://api.whatsapp.com/send?phone=62895326373394&text=Hai, saya dengan ID Toko ${userId}, Kebingungan dalam Menyusun *Creative Breafing*, tolong dibantu`, '_blank');
              }
            };
            
            confusedButton.addEventListener('click', handleConfusedButtonClick);
            
            const previewCreativeBreafing = document.getElementById("previewCreativeBreafing");
            
            const handlePreviewCreativeBreafingClick = () => {
              window.location = "/creative-breafing/";
            };
            
            previewCreativeBreafing.addEventListener('click', handlePreviewCreativeBreafingClick);
            
            const logoutMobile = document.getElementById("logout-mobile");
            
            const handleLogoutMobileClick = () => {
              signOut(auth)
                .then(() => {
                  window.location = "/login/";
                })
                .catch((error) => {
                  const message = error.code + ": " + error.message;
                  handleError(message);
                });
            };
            
            logoutMobile.addEventListener('click', handleLogoutMobileClick);
            
            const normalizePhoneNumber = (phoneNumber) => {
              if (phoneNumber.startsWith("08")) {
                return "+62" + phoneNumber.substring(1);
              }
              if (phoneNumber.startsWith("+62")) {
                return phoneNumber;
              }
              return phoneNumber;
            };
            
            const handleNavButtonClick = () => {
              navMobile.classList.toggle("active");
            };
            
            navButton.addEventListener('click', handleNavButtonClick);
            
            return () => {
              unsubscribe();
              // Remove event listeners
              keluarHeader.removeEventListener('click', handleKeluarHeaderClick);
              saveData.removeEventListener('click', handleSaveData);
              chatUs.removeEventListener('click', handleChatUs);
              urlTokoEdit.removeEventListener('input', handleUrlTokoEditInput);
              confusedButton.removeEventListener('click', handleConfusedButtonClick);
              previewCreativeBreafing.removeEventListener('click', handlePreviewCreativeBreafingClick);
              logoutMobile.removeEventListener('click', handleLogoutMobileClick);
              navButton.removeEventListener('click', handleNavButtonClick);
            };
            }, []);
    return (
        <>
        <Helmet>
            <title>Dashboard - tokoonlinemu.</title>
        </Helmet>
        <DashboardHeader/>
        <main id="dashboard-main" className="flex-coloumn">
        <div className="flex-coloumn center card-div" id="div-1">
          <div id="loading" />
          <div className="user-info flex-coloumn center" id="user-info">
            <img src alt="" id="photo-profile" width="96px" />
            <p className="m600" id="username" />
            <p className="m400" id="email" />
          </div>
        </div>
        <div className="flex-coloumn center card-div" style={{marginTop: '20px'}} id="div-2">
          <p className="m600" style={{marginBottom: '10px'}}>Creative Breafing</p>
          <Toast/>
          <div className="loading" id="data-toko-loading" style={{display: 'block'}} />
          <div className="info-text flex-coloumn" style={{width: '100%', display: 'none'}} id="info-text-toko">
            <img src alt="User Toko Icon" id="logo-toko" style={{alignSelf: 'center'}} width="65px" />
            <div className="textarea-container flex-coloumn">
              <button className="upload-button m400 flex-row center pointer" style={{marginBottom: '10px'}} id="photo-profile-toko-edit">
                <img src={Images.picture} alt="Picture Icon" style={{marginRight: '10px'}} width="24px" />
                <p>Ganti logo Toko</p>
              </button>
              <input type="file" name="choosePicture" id="choosePicture" />
              <p className="m400" style={{fontSize: '12px'}}>Nama Toko :</p>
              <div className="card-textarea flex-row center-left" style={{marginBottom: '10px'}}>
                <img src={Images.store} alt="Store Icon" width="20px" />
                <input type="text" name="name-toko-edit" id="nama-toko-edit" className="m400" placeholder="Masukkan Nama Tokomu" />
              </div>
              <p className="m400" style={{fontSize: '12px'}}>Slogan Toko :</p>
              <div className="card-textarea flex-row center-left" style={{marginBottom: '10px'}}>
                <img src={Images.tableOfContent} alt="Table of Content Icon" width="20px" />
                <input type="text" name="slogan-toko-edit" id="slogan-toko-edit" className="m400" placeholder="Masukkan Slogan Tokomu" />
              </div>
              <p className="m400" style={{fontSize: '12px'}}>Situs URL Toko :</p>
              <div className="card-textarea flex-row center-left" style={{marginBottom: '10px'}}>
                <img src={Images.url} alt="URL Icon" width="20px" />
                <input type="text" name="url-toko-edit" id="url-toko-edit" className="m400" placeholder="Masukkan URL yang kamu inginkan" title="Hanya huruf dan angka yang diperbolehkan" pattern="[a-zA-Z0-9]*" />
                <p className="m500" id="subdomain">.web.app</p>
              </div>
              <p className="m400" style={{fontSize: '12px'}}>Email Toko :</p>
              <div className="card-textarea flex-row center-left" style={{marginBottom: '10px'}}>
                <img src={Images.email} alt="Email Icon" width="20px" />
                <input type="email" name="email-toko-edit" id="email-toko-edit" className="m400" placeholder="Masukkan Email/Gmail tokomu"/>
              </div>
              <button className="flex-row center upload-button m400" style={{marginBottom: '5px'}}>
                <p>Media Sosial</p>
              </button>
              <div id="media-sosial" className="flex-coloumn center">
                <div className="card-textarea flex-row center-left" style={{marginBottom: '10px'}}>
                  <img src={Images.instagramDashboard} alt="Instagram Icon" width="20px" />
                  <input type="text" name="instagram-toko-edit" id="instagram-toko-edit" className="m400" placeholder="@usernameIGTokomu" />
                </div>
                <div className="card-textarea flex-row center-left" style={{marginBottom: '10px'}}>
                  <img src={Images.facebook} alt="Facebook Icon" width="20px" />
                  <input type="text" name="facebook-toko-edit" id="facebook-toko-edit" className="m400" placeholder="usernameFBTokomu" />
                </div>
                <div className="card-textarea flex-row center-left" style={{marginBottom: '10px'}}>
                  <img src={Images.twitter} alt="Twitter Icon" width="20px" />
                  <input type="text" name="twitter-toko-edit" id="twitter-toko-edit" className="m400" placeholder="@usernameTwitterTokomu" />
                </div>
                <div className="card-textarea flex-row center-left" style={{marginBottom: '10px'}}>
                  <img src={Images.whatsappDashboard} alt="Whatsapp Icon" width="20px" />
                  <p className="m500">+62</p>
                  <input type="text" name="whatsapp-toko-edit" id="whatsapp-toko-edit" className="m400" placeholder="+62 - Nomor Official Tokomu" />
                </div>
              </div>
              <button className="flex-row center upload-button m400" style={{marginBottom: '5px'}}>
                <p>Latar Belakang</p>
              </button>
              <textarea name="project-background" id="project-background" placeholder="Coba berikan profil usaha kamu dan permasalahan mengapa membutuhkan Landing Page?" className="m400" style={{padding: '5px', marginBottom: '10px', height: '50px', fontSize: '14px'}} defaultValue={""} />
              <button className="flex-row center upload-button m400" style={{marginBottom: '5px'}}>
                <p>Pengetahuan Produk</p>
              </button>
              <textarea name="product-knowledge" id="product-knowledge" placeholder="Coba berikan informasi produk kamu mencakup kelebihan produk, harga produk, varian produk dan metode marketing yang kamu lakukan sebelumnya" className="m400" style={{padding: '5px', marginBottom: '10px', height: '50px', fontSize: '14px'}} defaultValue={""} />
              <button className="flex-row center upload-button m400" style={{marginBottom: '5px'}}>
                <p>Objektif - Tujuan</p>
              </button>
              <textarea name="objective" id="objective" placeholder="Coba berikan tujuan pembuatan landing page untuk produk/usaha kamu?" className="m400" style={{padding: '5px', marginBottom: '10px', height: '50px', fontSize: '14px'}} defaultValue={""} />
              <button className="flex-row center upload-button m400" style={{marginBottom: '5px'}}>
                <p>Target Pelanggan</p>
              </button>
              <textarea name="target-audience" id="target-audience" placeholder="Coba jabarkan siapa target pasar dari produk/usaha anda?" className="m400" style={{padding: '5px', marginBottom: '10px', height: '50px', fontSize: '14px'}} defaultValue={""} />
              <button className="flex-row center upload-button m400" style={{marginBottom: '5px'}}>
                <p>Kunci Pesan</p>
              </button>
              <textarea name="message-key" id="message-key" placeholder="Kira-kira kunci pesan apa yang ingin kamu sampaikan mengenai produk/usaha nya kamu ke orang lain?" className="m400" style={{padding: '5px', marginBottom: '10px', height: '50px', fontSize: '14px'}} defaultValue={""} />
              <button className="flex-row center upload-button m400" style={{marginBottom: '5px'}}>
                <p>Nada dan Cara Desain</p>
              </button>
              <textarea name="design-tone-and-manner" id="design-tone-and-manner" placeholder="Kira-kira tema dan gaya desain apa yang dapat menunjukkan identitas visual dari produk/usaha kamu?" className="m400" style={{padding: '5px', marginBottom: '10px', height: '50px', fontSize: '14px'}} defaultValue={""} />
              <button className="flex-row center upload-button m400" style={{marginBottom: '5px'}}>
                <p>Kata kunci / Copywriting Required</p>
              </button>
              <textarea name="copywriting-required" id="copywriting-required" placeholder="Kira-kira poin-poin informasi apa yang akan dimasukkan pada landing page produk/usaha kamu?" className="m400" style={{padding: '5px', marginBottom: '10px', height: '50px', fontSize: '14px'}} defaultValue={""} />
              <button className="flex-row center upload-button m400" style={{marginBottom: '5px'}}>
                <p>Elemen Wajib</p>
              </button>
              <textarea name="mandatory-element" id="mandatory-element" placeholder="Kira-kira elemen apa yang wajib ada di Landing Page produk/usaha kamu? salah satu contohnya adalah logo halal" className="m400" style={{padding: '5px', marginBottom: '10px', height: '50px', fontSize: '14px'}} defaultValue={""} />
              <button className="flex-row center upload-button m400" style={{marginBottom: '5px'}}>
                <p>Rincian Media</p>
              </button>
              <textarea name="media-details" id="media-details" placeholder="Apa tambahan yang diluar dari yang diatas yang ingin kamu tambahkan dalam landing page produk/usaha kamu?" className="m400" style={{padding: '5px', marginBottom: '10px', height: '50px', fontSize: '14px'}} defaultValue={""} />
              <p className="m400 text-center" style={{marginTop: '20px', fontSize: '12px', width: '100%'}}><b>Bingung?</b> Hubungi Kami Yuk!, Kami akan bantu kamu menyusunnya secara runut!</p>
              <button className="upload-button flex-row center m400 pointer" style={{marginTop: '5px'}} id="confused">
                <img src={Images.call} alt="Call Icon" style={{marginRight: '10px', rotate: '-95deg'}} width="24px" />
                <p>Hubungi Kami Yuk!</p>
              </button>
              <div style={{width: '100%', marginTop: '10px', marginBottom: '5px', borderTop: '1px solid grey'}} />
              <button className="upload-button flex-row center m400 pointer" style={{marginTop: '5px'}} id="saveData">
                <img src={Images.save} alt="Save Icon" style={{marginRight: '10px'}} width="24px" />
                <p>Simpan Data Toko</p>
              </button>
              <button className="upload-button flex-row center m400 pointer" style={{marginTop: '5px'}} id="previewCreativeBreafing">
                <img src={Images.preview} alt="Save Icon" style={{marginRight: '10px'}} width="24px" />
                <p>Preview Creative Breafing</p>
              </button>
              <button className="upload-button flex-row center m400 pointer" style={{marginTop: '5px'}} id="chatUs">
                <img src={Images.whatsappDashboard} alt="Save Icon" style={{marginRight: '10px'}} width="24px" />
                <p>Chat Kami Untuk Membuat</p>
              </button>
            </div>
          </div>
        </div>
      </main>
        <Footer/>
        </>
    )
}

export default Dashboard