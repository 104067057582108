// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Home from './pages/home/Home';
import Dashboard from './pages/dashboard/Dashboard';
import CreativeBreafing from './pages/creative-breafing/CreativeBreafing';
import Login from './pages/login/Login';
import Signup from './pages/signup/Signup';
import { HelmetProvider } from 'react-helmet-async';

function App() {
  return (
    <HelmetProvider>
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />}/>
          <Route exact path="login/" element={<Login />}/>
          <Route exact path="login" element={<Login />}/>
          <Route exact path='signup' element={<Signup />}/>
          <Route exact path='signup/' element={<Signup />}/>
          <Route exact path='dashboard' element={<Dashboard/>}/>
          <Route exact path='dashboard/' element={<Dashboard/>}/>
          <Route exact path='creative-breafing' element={<CreativeBreafing/>}/>
          <Route exact path='creative-breafing/' element={<CreativeBreafing/>}/>
        </Routes>
      </Router>
    </HelmetProvider>

  );
}

export default App;