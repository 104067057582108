import React, { useEffect } from "react";
import '../../App.css';
import { Helmet } from "react-helmet-async";
import DashboardHeader from "../../components/DashboardHeader";
import Footer from "../../components/Footer";
import Images from "../../components/Images";
import { auth, database } from "../../firebase";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { ref, child, get } from "firebase/database";

const CreativeBreafing = () => {
    useEffect(() => {
        const navButton = document.getElementById("nav-button");
        const navMobile = document.getElementById("navigation-mobile");
      
        const handleNavButtonClick = () => {
          if (navMobile.className.includes("active")) {
            navMobile.classList.remove("active");
          } else {
            navMobile.classList.add("active");
          }
        };
      
        navButton.addEventListener('click', handleNavButtonClick);
      
        const unsubscribe = onAuthStateChanged(auth, (user) => {
          if (user) {
            getCreativeBreafingData(user);
          } else {
            window.location = "/login/";
          }
        });
      
        const projectBackground = document.getElementById("project-background");
        const productKnowledge = document.getElementById("product-knowledge");
        const objective = document.getElementById("objective");
        const targetAudience = document.getElementById("target-audience");
        const messageKey = document.getElementById("message-key");
        const designToneAndManner = document.getElementById("design-tone-and-manner");
        const copywritingRequired = document.getElementById("copywriting-required");
        const mandatoryElement = document.getElementById("mandatory-element");
        const mediaDetails = document.getElementById("media-details");
        const dataCBLoading = document.getElementById("loading");
        const cbTextParent = document.getElementById("cb-text-parent");
        const cbMain = document.getElementById("cb-main");
        const backToDashboard = document.getElementById("back-to-dashboard");
        const callUs = document.getElementById("call-us");
      
        // Toast Element
        const ST = document.getElementById("success-toast");
        const ET = document.getElementById("error-toast");
        const STT = document.getElementById("success-title-toast");
        const SMT = document.getElementById("success-message-toast");
        const ETT = document.getElementById("error-title-toast");
        const EMT = document.getElementById("error-message-toast");
      
        const handleError = (message) => {
          ET.style.display = "flex";
          EMT.innerText = message;
          setTimeout(() => {
            ET.style.display = "none";
          }, 3000);
        };
      
        const handleBackToDashboard = () => {
          window.location = "/dashboard/";
        };
      
        const handleCallUs = () => {
          const userUID = auth.currentUser.uid;
          window.open(`https://api.whatsapp.com/send?phone=62895326373394&text=Hai, saya dengan ID Toko ${userUID}, Ingin mengajak berkolaborasi *tokoonlinemu.* untuk membuat website kami!`, '_blank');
        };
      
        backToDashboard.addEventListener('click', handleBackToDashboard);
        callUs.addEventListener('click', handleCallUs);
      
        const getCreativeBreafingData = (user) => {
          const dbRef = ref(database);
          get(child(dbRef, `users/${user.uid}/datatoko`))
            .then((snapshot) => {
              if (snapshot.exists()) {
                const projectBackgrounddb = snapshot.val()["creative-breafing"].projectBackground || "-";
                const productKnowledgedb = snapshot.val()["creative-breafing"].productKnowledge || "-";
                const objectivedb = snapshot.val()["creative-breafing"].objective || "-";
                const targetAudiencedb = snapshot.val()["creative-breafing"].targetAudience || "-";
                const keyMessagedb = snapshot.val()["creative-breafing"].keyMessage || "-";
                const designToneAndMannerdb = snapshot.val()["creative-breafing"].designToneAndManner || "-";
                const copywritingRequireddb = snapshot.val()["creative-breafing"].copywritingRequired || "-";
                const mandatoryElementdb = snapshot.val()["creative-breafing"].mandatoryElement || "-";
                const mediaDetailsdb = snapshot.val()["creative-breafing"].mediaDetails || "-";
      
                dataCBLoading.style.display = "none";
                cbMain.style.height = "100%";
      
                projectBackground.innerText = projectBackgrounddb;
                productKnowledge.innerText = productKnowledgedb;
                objective.innerText = objectivedb;
                targetAudience.innerText = targetAudiencedb;
                messageKey.innerText = keyMessagedb;
                designToneAndManner.innerText = designToneAndMannerdb;
                copywritingRequired.innerText = copywritingRequireddb;
                mandatoryElement.innerText = mandatoryElementdb;
                mediaDetails.innerText = mediaDetailsdb;
      
                cbTextParent.style.display = "flex";
              } else {
                const message = "Data tidak ada, coba Daftar Ulang untuk Mengatasinya";
                handleError(message);
              }
            })
            .catch((error) => {
              const message = error.code + ": " + error.message;
              handleError(message);
            });
        };
      
        const logoutMobile = document.getElementById("logout-mobile");
      
        const handleLogoutMobile = () => {
          signOut(auth)
            .then(() => {
              window.location = "/login/";
            })
            .catch((error) => {
              const message = error.code + ": " + error.message;
              handleError(message);
            });
        };
      
        logoutMobile.addEventListener('click', handleLogoutMobile);
      
        return () => {
          unsubscribe();
          navButton.removeEventListener('click', handleNavButtonClick);
          backToDashboard.removeEventListener('click', handleBackToDashboard);
          callUs.removeEventListener('click', handleCallUs);
          logoutMobile.removeEventListener('click', handleLogoutMobile);
        };
      }, []);

    return(
        <>
        <Helmet>
            <title>Creative Breafing - tokoonlinemu.</title>
        </Helmet>
        <DashboardHeader/>
        <main className="dashboard-main flex-coloumn center cb-main" id="cb-main">
        <div id="loading" />
        <div className="cb-text-parent flex-coloumn center" id="cb-text-parent">
          <div className="cb-text-container flex-row">
            <div className="cb-text-left-container">
              <p className="m600"> Project Background<span className="m400"> - <i>Latar Belakang</i></span></p>
            </div>
            <div className="cb-text-right-container">
              <p className="m400" id="project-background" />
            </div>
          </div>
          <div className="cb-text-container flex-row">
            <div className="cb-text-left-container">
              <p className="m600">Product Knowledge<span className="m400"> - <i>Pengetahuan Produk</i></span></p>
            </div>
            <div className="cb-text-right-container">
              <p className="m400" id="product-knowledge" />
            </div>
          </div>
          <div className="cb-text-container flex-row">
            <div className="cb-text-left-container">
              <p className="m600">Objectives<span className="m400"> - <i>Tujuan</i></span></p>
            </div>
            <div className="cb-text-right-container">
              <p className="m400" id="objective" />
            </div>
          </div>
          <div className="cb-text-container flex-row">
            <div className="cb-text-left-container">
              <p className="m600">Target Audience<span className="m400"> - <i>Target Pelanggan</i></span></p>
            </div>
            <div className="cb-text-right-container">
              <p className="m400" id="target-audience" />
            </div>
          </div>
          <div className="cb-text-container flex-row">
            <div className="cb-text-left-container">
              <p className="m600">Key Message<span className="m400"> - <i>Kunci Pesan</i></span></p>
            </div>
            <div className="cb-text-right-container">
              <p className="m400" id="message-key" />
            </div>
          </div>
          <div className="cb-text-container flex-row">
            <div className="cb-text-left-container">
              <p className="m600">Design Tone and Manner<span className="m400"> - <i>Nada dan Cara Desain</i></span></p>
            </div>
            <div className="cb-text-right-container">
              <p className="m400" id="design-tone-and-manner" />
            </div>
          </div>
          <div className="cb-text-container flex-row">
            <div className="cb-text-left-container">
              <p className="m600">Copywriting Required<span className="m400"> - <i>Kata Kunci</i></span></p>
            </div>
            <div className="cb-text-right-container">
              <p className="m400" id="copywriting-required" />
            </div>
          </div>
          <div className="cb-text-container flex-row">
            <div className="cb-text-left-container">
              <p className="m600">Mandatory Element<span className="m400"> - <i>Elemen Wajib</i></span></p>
            </div>
            <div className="cb-text-right-container">
              <p className="m400" id="mandatory-element" />
            </div>
          </div>
          <div className="cb-text-container flex-row">
            <div className="cb-text-left-container">
              <p className="m600">Media Details<span className="m400"> - <i>Detail Media</i></span></p>
            </div>
            <div className="cb-text-right-container" style={{borderBottom: '1px solid rgb(235, 235, 235)'}}>
              <p className="m400" id="media-details" />
            </div>
          </div>
          <div className="cb-button flex-coloumn center">
            <button className="m400 center" id="back-to-dashboard">
              <img src={Images.dashboardWhite} alt="Dashboard Icon" width="24px" height="24px" />
              <p>Kembali Ke Dashboard</p>
            </button>
            <button className="m400 center" id="call-us">
              <img src={Images.whatsappWhite} alt="Whatsapp Icon" width="24px" height="24px" />
              <p>Chat Kami Untuk Membuat</p>
            </button>
          </div>
        </div>
      </main>
        <Footer/>
        </>
    )
}

export default CreativeBreafing