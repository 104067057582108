import React from "react";
import '../App.css'
import Images from "./Images";

const LoginHeader = () => {
    return (
        <header>
        <div className="header-wrap flex-row center">
          <img src={Images.logo} alt="Logo TokoOnlinemu" width="35px" height="35p" />
          <div className="flex-column">
            <p className="grey-text m700" id="title-text">toko<span className="pink-text">online</span>mu.</p>
            <p className="m400" id="slogan-text">Onlinekan Tokomu Bersama Kami</p>
          </div>
          <div className="m400 flex-row" id="navigation-desktop">
            <a href="/" className="flex-row center">
              <img src={Images.home} alt="Home Icon" style={{marginRight: '5px'}} width="20px" height="20px" />
              <p>Beranda</p>
            </a>
          </div>
          <div className="nav-wrap flex-row center-right m400">
            <button className="nav-button" id="nav-button">
              <img className="center flex-row" src={Images.menu} alt="Menu icon" width="25px" height="25px" />
            </button>
            <div className="flex-row" id="button-login-header">
              <button className="header-button flex-row center pointer" style={{marginRight: '5px'}} id="daftar-header">
                <img src={Images.register} alt="Register Icon" style={{marginRight: '5px'}} width="24px" />
                <p className="m500">Daftar</p>
              </button>
              <button className="header-button flex-row center pointer" id="masuk-header">
                <img src={Images.login} alt="Register Icon" style={{marginRight: '5px'}} width="20px" />
                <p className="m500">Masuk</p>
              </button>
            </div>
          </div>
        </div>
        <div className="center m400" id="navigation-mobile">
          <a href="/" className="flex-row center">
            <img src={Images.home} alt="Home Icon" style={{marginRight: '5px'}} width="20px" height="20px" />
            <p style={{minWidth: '70px'}}>Beranda</p>
          </a>
          <a href="/signup/" className="flex-row center" id="signup-mobile">
            <img src={Images.register} alt="Register Icon" style={{marginRight: '5px'}} width="24px" height="24px" />
            <p style={{minWidth: '70px'}}>Daftar</p>
          </a>
          <a href="/login/" className="flex-row center" id="login-mobile">
            <img src={Images.login} alt="User Icon" style={{marginRight: '5px'}} width="24px" height="24px" />
            <p style={{minWidth: '70px'}}>Masuk</p>
          </a>
        </div>
      </header>
    )
}

export default LoginHeader