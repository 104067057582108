import React from 'react';
import '../App.css'
import Images from './Images';

const Footer = () => {
  return (
    <footer className="flex-column center-left">
      <div className="flex-row center">
        <div className="logo-wrap flex-row logo-footer">
          <img id="logo-footer" src={Images.logo} alt="tokoonlinemu. Logo" width="40px" />
          <div className="flex-coloumn center">
            <p className="m700 grey-text" id="title-text" style={{ alignSelf: 'self-start' }}>toko<span className="pink-text">online</span>mu.</p>
            <p className="m400 grey-text" id="slogan-text" style={{ alignSelf: 'self-start' }}>Onlinekan Tokomu Bersama Kami</p>
          </div>
        </div>
        <div id="flex-footer">
          i
        </div>
        <a href="#" className="arrow-up flex-row center">
          <img src={Images.arrowUp} alt="Arrow Up icon" height="30px" />
        </a>
      </div>
    </footer>
  );
}

export default Footer;