import '../../App.css';
import React, { useEffect } from "react";
import { onAuthStateChanged, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { get, ref, set } from "firebase/database"
import { ref as refStorage, uploadBytes, getDownloadURL } from "firebase/storage"
import { auth, database, storage } from "../../firebase";
import '../../App.css';
import LoginHeader from "../../components/LoginHeader";
import Footer from "../../components/Footer";
import { Helmet } from "react-helmet-async";
import Toast from '../../components/Toast';
import Images from '../../components/Images';

const Signup = () => {
    useEffect(() => {
        const provider = new GoogleAuthProvider();
        auth.languageCode = "id";

        // DOM elements
        const navButton = document.getElementById("nav-button");
        const navMobile = document.getElementById("navigation-mobile");
        const daftarHeader = document.getElementById("daftar-header");
        const masukHeader = document.getElementById("masuk-header");
        const loginWithGoogle = document.getElementById("loginWithGoogle");
        const ST = document.getElementById("success-toast");
        const ET = document.getElementById("error-toast");
        const STT = document.getElementById("success-title-toast");
        const SMT = document.getElementById("success-message-toast");
        const ETT = document.getElementById("error-title-toast");
        const EMT = document.getElementById("error-message-toast");

        // Event listeners
        
        const handleAuthStateChanged = (user) => {
            if (user) {
                const userRef = ref(database, 'users/' + user.uid);
        
            get(userRef)
                .then((snapshot) => {
                    if (snapshot.exists()) {
                        const message = "Berhasil Login"
                        handleSuccess(message)
                        setTimeout(() => {
                            window.location = "../dashboard/"
                        }, 3000)                } else {
                        writeUserData(user.uid, user.displayName, user.email, user.photoURL);
                    }
                })
                .catch((error) => {
                    const message = error.code + ": " + error.message;
                    handleError(message);
                });
            }
        }

        const unsubscribe = onAuthStateChanged(auth, handleAuthStateChanged);

        const handleError = (message) => {
            ET.style.display = "flex";
            EMT.innerText = message;
            setTimeout(() => {
                ET.style.display = "none";
            }, 3000);
        }
        
        const handleSuccess = (message) => {
            ST.style.display = "flex";
            SMT.innerText = message;
            setTimeout(() => {
                ST.style.display = "none";
            }, 3000);
        }

        const toggleNavigation = () => {
            navMobile.classList.toggle("active");
        }

        const redirectToHome = () => {
            window.location = "/signup/";
        }
        
        const redirectToLogin = () => {
            window.location = "/login/";
        }
        
        const loginWithGooglePopup = () => {
            signInWithPopup(auth, provider)
            .then((result) => {
                const user = result.user;
                const userRef = ref(database, 'users/' + user.uid);
                
                get(userRef)
                    .then((snapshot) => {
                        if (snapshot.exists()) {
                            const message = "Berhasil Login"
                            handleSuccess(message)
                            setTimeout(() => {
                                window.location = "/dashboard/"
                            }, 3000)                
                        } else {
                            writeUserData(user.uid, user.displayName, user.email, user.photoURL)
                        }
                    })
                    .catch((error) => {
                        const message = error.code + ": " + error.message
                        handleError(message)
                    })
            })
            .catch((error) => {
                const message = error.code + ": " + error.message
                handleError(message)
            })
        }
        
        const writeUserData = (userID, name, email, photoURL) => {
            const storageRef = refStorage(storage, 'users/' + userID + '/logo.png');
            
            fetch(photoURL)
            .then(response => response.blob())
            .then(blob => {
                uploadBytes(storageRef, blob)
                .then((snapshot) => {
                    getDownloadURL(snapshot.ref)
                    .then((downloadURL) => {
                        set(ref(database, 'users/' + userID), {
                            username: name,
                            email: email,
                            photoURL: downloadURL,
                            datatoko: {
                                "creative-breafing": {
                                    projectBackground: "",
                                    productKnowledge: "",
                                    objective: "",
                                    targetAudience: "",
                                    keyMessage: "",
                                    designToneAndManner: "",
                                    copywritingRequired: "",
                                    mandatoryElement: "",
                                    mediaDetails: ""
                                },
                                emailtoko: email,
                                logotoko: downloadURL,
                                mediasosial: {
                                    facebook: "",
                                    instagram: "",
                                    twitter: "",
                                    whatsapp: "",
                                },
                                namatoko: "Toko " + name,
                                slogantoko: "",
                                urltoko: ""
                            }
                        })
                        .then(() => {
                            const message = "Berhasil Daftar"
                            handleSuccess(message)
                            setTimeout(() => {
                                window.location = "/dashboard/"
                            }, 3000)
                        })
                        .catch((error) => {
                            const message = error.code + ": " + error.message
                            handleError(message)
                        });
                    })
                    .catch((error) => {
                        const message = "Error mengambil URL unduhan: " + error.message;
                        handleError(message);
                    });
                })
                .catch((error) => {
                    const message = "Error mengunggah foto profil: " + error.message;
                    handleError(message);
                });
            })
            .catch((error) => {
                const message = "Error mengambil gambar: " + error.message;
                handleError(message);
            });
        }

        navButton.addEventListener('click', toggleNavigation);
        daftarHeader.addEventListener('click', redirectToHome);
        masukHeader.addEventListener('click', redirectToLogin);
        loginWithGoogle.addEventListener('click', loginWithGooglePopup)
        
        return () => {
            unsubscribe();
            navButton.removeEventListener('click', toggleNavigation);
            daftarHeader.removeEventListener('click', redirectToHome);
            masukHeader.removeEventListener('click', redirectToLogin);
            loginWithGoogle.removeEventListener('click', loginWithGooglePopup)    
        };
    }, [])

    return (
        <>
            <Helmet>
                <title>Daftar - tokoonlinemu.</title>
            </Helmet>
            <LoginHeader />
            <main className="div-100vh flex-coloumn center" id="register-login-main">
                <div className="brand-name flex-coloumn center" style={{height: '50%'}}>
                <img src={Images.userProfilePhoneScreen} alt="User Phone Profile Screen" style={{marginBottom: '10px'}} height="180px" />
                <p className="m600" style={{fontSize: '24px'}}>Ayo kita mulai!</p>
                <p className="m400" style={{fontSize: '14px'}}>Kami Senang melihat anda disini!</p>            
                </div>
                <div className="login-form flex-coloumn center" style={{height: '50%'}}>
                <Toast/>
                <button className="flex-row center google-button pointer" id="loginWithGoogle" style={{marginTop: '10px'}}>
                    <img src={Images.google} alt="Google icon" width="20px" />
                    <p className="m400">Daftarkan dengan Google</p>
                </button>
                <p className="m300 text-center" style={{fontSize: '12px', marginTop: '3px'}}>Dengan Google Login, kamu tidak perlu repot untuk verifikasi akun, kami melakukan ini untuk anda!</p>
                </div>
            </main>
            <Footer />
        </>
    )

}

export default Signup