import React, { useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../../firebase";
import '../../App.css';
import 'lite-youtube-embed'
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { Helmet } from "react-helmet-async";
import Images from "../../components/Images";

const Home = () => {
    useEffect(() => {
        const handleAuthStateChanged = (user) => {
          const masukHeader = document.getElementById("masuk-header");
          const daftarHeader = document.getElementById("daftar-header");
          const dashboardHeader = document.getElementById("dashboard-header");
          const signupMobile = document.getElementById("signup-mobile");
          const loginMobile = document.getElementById("login-mobile");
          const dashboardMobile = document.getElementById("dashboard-mobile");
          const daftarSekarang = document.getElementById("daftar-sekarang");
          const dashboardSekarang = document.getElementById("dashboard-sekarang");
          const daftarRagu = document.getElementById("daftar-ragu");
          const dashboardRagu = document.getElementById("dashboard-ragu");
    
          if (user) {
            masukHeader.style.display = "none";
            daftarHeader.style.display = "none";
            dashboardHeader.style.display = "flex";
            signupMobile.style.display = "none";
            loginMobile.style.display = "none";
            dashboardMobile.style.display = "flex";
            daftarSekarang.style.display = "none";
            dashboardSekarang.style.display = "block";
            daftarRagu.style.display = "none";
            dashboardRagu.style.display = "flex";
          } else {
            masukHeader.style.display = "flex";
            daftarHeader.style.display = "flex";
            dashboardHeader.style.display = "none";
            signupMobile.style.display = "flex";
            loginMobile.style.display = "flex";
            dashboardMobile.style.display = "none";
            daftarSekarang.style.display = "block";
            dashboardSekarang.style.display = "none";
            daftarRagu.style.display = "flex";
            dashboardRagu.style.display = "none";
          }
        };
    
        const unsubscribe = onAuthStateChanged(auth, handleAuthStateChanged);
    
        const handleNavButtonClick = () => {
          const navMobile = document.getElementById("navigation-mobile");
          if (navMobile.className.includes("active")) {
            navMobile.classList.remove("active");
          } else {
            navMobile.classList.add("active");
          }
        };
    
        const handleLearnMoreClick = () => {
          window.location = "./#about-us";
        };

        const handleDaftarSekarangClick = () => {
            window.location = "/signup"
        }

        const handeDashboardSekarangClick = () => {
            window.location = "/dashboard"
        }

        const handleDaftarRaguClick = () => {
            window.location = "/signup"
        }

        const handleDashboardRaguClick = () => {
            window.location = "/dashboard"
        }
    
        const handleGmailButtonClick = () => {
          const subjectGmail = document.getElementById("subject-gmail").value;
          const bodyGmail = document.getElementById("body-gmail").value;
          window.open(`https://mail.google.com/mail/u/0/?view=cm&fs=1&tf=1&source=mailto&to=tokoonlinemuid@gmail.com&su=${subjectGmail}&body=${bodyGmail}`, "_blank");
        };
    
        const handleWhatsAppButtonClick = () => {
          window.open(`https://api.whatsapp.com/send?phone=62895326373394&text=Hai, saya ingin bertanya-tanya suatu hal mengenai *tokoonlinemu.*`, '_blank');
        };
    
        const handleInstagramButtonClick = () => {
          window.open('https://www.instagram.com/tokoonlinemuid/', "_blank");
        };
    
        const navButton = document.getElementById("nav-button");
        const learnMore = document.getElementById("learn-more");
        const gmailButton = document.getElementById("gmail-button");
        const whatsAppButton = document.getElementById("whatsapp-button");
        const instagramButton = document.getElementById("instagram-button");
        const daftarSekarang = document.getElementById("daftar-sekarang");
        const dashboardSekarang = document.getElementById("dashboard-sekarang");
        const daftarRagu = document.getElementById("daftar-ragu");
        const dashboardRagu = document.getElementById("dashboard-ragu");
    
        navButton.addEventListener('click', handleNavButtonClick);
        learnMore.addEventListener('click', handleLearnMoreClick);
        gmailButton.addEventListener('click', handleGmailButtonClick);
        whatsAppButton.addEventListener('click', handleWhatsAppButtonClick);
        instagramButton.addEventListener('click', handleInstagramButtonClick);

        daftarSekarang.addEventListener('click', handleDaftarSekarangClick)
        dashboardSekarang.addEventListener('click', handeDashboardSekarangClick)
        daftarRagu.addEventListener('click', handleDaftarRaguClick)
        dashboardRagu.addEventListener('click', handleDashboardRaguClick)

    
        return () => {
          unsubscribe();
          navButton.removeEventListener('click', handleNavButtonClick);
          learnMore.removeEventListener('click', handleLearnMoreClick);
          gmailButton.removeEventListener('click', handleGmailButtonClick);
          whatsAppButton.removeEventListener('click', handleWhatsAppButtonClick);
          instagramButton.removeEventListener('click', handleInstagramButtonClick);
        };
      }, []);
    // 
    return (
        <>
            <Helmet>
                <title>tokoonlinemu. - Onlinekan Tokomu Bersama Kami</title>
            </Helmet>
            <Header/>
            <main className="flex-column center text-center" spellCheck="false">
            <div className="section-break flex-column center text-center" id="jumbotron">
            <img src={Images.jumbotron} alt="Handphone dengan Toko di Layar" id="jumbotron-img" height="200px" />
            <div className="text-jumbotron-wrap">
                <h1 className="m700">Wujudkan Impianmu dengan <b className="grey-text">toko<span className="pink-text">online</span>mu.</b></h1>
                <br />
                <p className="m400">Mari bergabung bersama kami dalam membangun <i>landing page</i> yang menakjubkan untuk produk-produk unggulanmu. Dengan kolaborasi yang menyenangkan, impianmu bisa menjadi kenyataan!</p>
                <br />
                <div className="flex-row center button-jumbotron-container">
                <button className="black-button m500 min-w220 pointer" id="learn-more">
                    <div className="flex-row center">
                    <img src={Images.study} alt="Buy Icon" style={{marginRight: '5px'}} width="20px" height="20px" />
                    <p style={{minWidth: "150px"}}>Pelajari Selengkapnya</p>
                    </div>
                </button>
                <button className="white-button m500 min-w220 pointer" id="daftar-sekarang">
                    <div className="flex-row center">
                    <img src={Images.login} alt="Buy Icon" style={{marginRight: '5px'}} width="20px" height="20px" />
                    <p style={{minWidth: "150px"}}>Daftar Sekarang</p>
                    </div>
                </button>
                <button className="white-button m500 min-w220 pointer" id="dashboard-sekarang" style={{display: 'none'}}>
                    <div className="flex-row center">
                    <img src={Images.dashboard} alt="Buy Icon" style={{marginRight: '5px'}} width="20px" height="20px" />
                    <p style={{minWidth: "150px"}}>Menuju ke Dashboard</p></div></button>
                </div>
            </div>
            </div>
            <div className="about-us section-break flex-row center" id="about-us">
            <div className="iframe-container flex-row center">
                <div className="iframe-about-us flex-row center">
                <lite-youtube videoid="0N1Tz6dnqdA" />
                </div>
            </div>
            <div className="about-us-text flex-row center">
                <div className="about-us-text-container">
                <h2 className="m700" style={{marginBottom: '10px'}}>Lebih kenal dengan produk kami dengan video iklan!</h2>
                <p className="m400">Bagi kami, keuntungan yang kecil namun banyak pelanggan itu lebih baik, dibandingkan keuntungan yang besar namun sedikit pelanggan. Pelanggan adalah mitra kami, kami akan selalu siap sedia untuk membantu hal yang dapat kami bantu dalam pemasaran produk pelanggan!</p>
                </div>
            </div>
            </div>
            <div className="feature section-break" id="features">
            <h2 className="m700">Fitur Unggulan!</h2>
            <p className="m400" id="12px">Apa yang Membuat Kami Spesial?</p>
            <div className="feature-wrap">
                <div className="feature-list flex-column center">
                <img src={Images.collaboration} alt="Ikon Kolaborasi" className="image-feature-list" width="60px" height="60px" />
                <h3 className="m600">Kolaborasi</h3>
                <p className="m400">Kami menawarkan kolaborasi tanpa batas untuk mencapai tujuan bersama dalam mengembangkan bisnis online kamu.</p>
                </div>
                <div className="feature-list flex-column center">
                <img src={Images.callService} alt="Ikon Layanan" className="image-feature-list" width="60px" height="60px" />
                <h3 className="m600">Layanan Terbaik</h3>
                <p className="m400">Kami siap membantu dari awal hingga akhir, menjadikan pengalamanmu dalam membangun toko online lebih mudah dan menyenangkan.</p>
                </div>
                <div className="feature-list flex-column center">
                <img src={Images.price} alt="Ikon Harga" className="image-feature-list" width="60px" height="60px" />
                <h3 className="m600">Harga Terjangkau</h3>
                <p className="m400">Dapatkan semua keunggulan kami dengan harga yang terjangkau dan transparan, tanpa biaya tersembunyi.</p>
                </div>
            </div>
            </div>
            <div className="call-to-action section-break flex-coloumn center">
            <h2 className="m700">Masih Ragukan Product Kami?</h2>
            <button className="black-button m500 pointer flex-row center" id="daftar-ragu">
                <img src={Images.registerWhite} alt="Buy Icon" style={{marginRight: '5px'}} width="20px" height="20px" />
                <p>Daftar Sekarang</p></button>
            <button className="black-button m500 pointer flex-row center" id="dashboard-ragu" style={{display: 'none'}}>
                <img src={Images.dashboardWhite} alt="Buy Icon" style={{marginRight: '5px'}} width="20px" height="20px" />
                <p>Menuju Ke Dashboard</p>
            </button>
            </div>
            <div className="call-to-action section-break flex-column center" id="contact-us">
            <h2 className="m700">Hubungi Kami!</h2>
            <p className="m400" id="12px" style={{marginBottom: '20px'}}>Bertanya atau bahkan membeli dipersilahkan</p>
            <div className="media-contact flex-coloumn center">
                <div className="gmail-contact flex-coloumn center">
                <div className="input-textarea-container flex-coloumn center">
                    <p className="m400">Subject/Judul Pesan :</p>
                    <div className="input-fix">
                    <input className="m600" type="text" name="Subject" id="subject-gmail" placeholder="Masukkan Subject Pesan" style={{fontSize: '15px'}} />
                    </div>
                    <p className="m400">Isi Pesan :</p>
                    <textarea className="m400" name="Body" id="body-gmail" placeholder="Masukkan isi pesan" style={{fontSize: '13px'}} defaultValue={""} />
                </div>
                <div className="media-contact-container flex-row center gmail pointer" id="gmail-button">
                    <div className="media-contact-container-image flex-row center">
                    <img src={Images.gmail} alt="Whatsapp icon" width="24px" />
                    </div>
                    <div className="m400 media-contact-container-text">
                    <p>Kirim Pesan ke Gmail Kami</p>
                    </div>
                </div>
                </div>
                <div className="media-social-contact flex-coloumn center">
                <div className="media-contact-container flex-row center whatsapp pointer" id="whatsapp-button">
                    <div className="media-contact-container-image flex-row center">
                    <img src={Images.whatsappWhite} alt="Whatsapp icon" width="24px" />
                    </div>
                    <div className="m400 media-contact-container-text">
                    <p>Chat dengan Kami di Whatsapp</p>
                    </div>
                </div>
                <div className="media-contact-container flex-row center instagram pointer" id="instagram-button">
                    <div className="media-contact-container-image flex-row center">
                    <img src={Images.instagramWhite} alt="Whatsapp icon" width="24px" />
                    </div>
                    <div className="m400 media-contact-container-text">
                    <p>Kunjungi Instagram Kami</p>
                    </div>
                </div>
                </div>
            </div>
            </div>
            <div className="founder section-break flex-column center" id="founders">
            <h2 className="m700">Bertemu dengan founder!</h2>
            <p className="m400" id="12px">Orang-orang hebat pencipta website hebat ini</p>
            <div className="founder-wrap">
                <div className="founder-list" style={{marginRight: "10px"}}>
                <img src={Images.zulfachrie} alt="Zulfachrie Photo Profile" className="photo-profile" width="128px" />
                <br />
                <p id="founder-name" className="m600">Muhammad Zulfachrie Nasution</p>
                <p id="role-text" className="m400">Founder of <b className="grey-text">toko<span className="pink-text">online</span>mu.</b></p>
                <br />
                <p className="m400">"Seorang Siswa SMA yang bercita-cita sebagai seorang Startup Leader di masa depan"</p>
                <br />
                <p className="m400" id="media-sosial">Media Sosial</p>
                <div className="media-sosial flex-row center">
                    <a href="https://www.instagram.com/zulfachrie_/" target="_blank"><img src={Images.instagram} alt="Instagram icon" width="25px" /></a>
                    <a href="https://wa.me/+62895326373394" target="_blank"><img src={Images.whatsapp} alt="Whatsapp icon" width="25px" /></a>
                    <a href="https://www.youtube.com/@ririgfx" target="_blank"><img src={Images.youtube} alt="Youtube icon" width="25px" /></a>
                </div>
                </div>
                <div className="founder-list" style={{marginLeft: "10px"}}>
                <img src={Images.sylvia} alt="Zulfachrie Photo Profile" className="photo-profile" width="128px" />
                <br />
                <p id="founder-name" className="m600">Sylvia Putri Br. Sinaga</p>
                <p id="role-text" className="m400">Co-founder of <b className="grey-text">toko<span className="pink-text">online</span>mu.</b></p>
                <br />
                <p className="m400">"Seorang Siswi SMA yang ambis, tak kenal lelah, terus maju tanpa henti, itulah dia, Sylvia namanya."</p>
                <br />
                <p className="m400" id="media-sosial">Media Sosial</p>
                <div className="media-sosial flex-row center">
                    <a href="https://www.instagram.com/sylviapputri/" target="_blank"><img src={Images.instagram} alt="Instagram icon" width="25px" /></a>
                    <a href="https://wa.me/+6281260973996" target="_blank"><img src={Images.whatsapp} alt="Whatsapp icon" width="25px" /></a>
                    <a href="https://www.youtube.com/@sylviapputri" target="_blank"><img src={Images.youtube} alt="Youtube icon" width="25px" /></a>
                </div>
                </div>
            </div>
            </div>
        </main>
        <Footer/>
        </>

    );
}

export default Home