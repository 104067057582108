import React from "react";
import '../App.css'
import Images from "./Images";

const Toast = () => {
    return(
        <div className="toast">
        <div className="error-toast" id="error-toast">
          <img src={Images.warning} alt="Icon Peringatan" width="36px" height="36px" />
          <div className="text-toast">
            <p className="title-toast m700" id="error-title-toast">Error</p>
            <p className="message-toast m400" id="error-message-toast" />
          </div>
        </div>
        <div className="success-toast" id="success-toast">
          <img src={Images.success} alt="Icon Berhasil" width="36px" height="36px" />
          <div className="text-toast">
            <p className="title-toast m700" id="success-title-toast">Berhasil</p>
            <p className="message-toast m400" id="success-message-toast" />
          </div>
        </div>
      </div>
    )
}

export default Toast