import React, { useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../firebase";
import '../App.css';
import Images from "./Images";

const Header = () => {
    useEffect(() => {
        const masukHeader = document.getElementById("masuk-header");
        const daftarHeader = document.getElementById("daftar-header");
        const dashboardHeader = document.getElementById("dashboard-header");
        const signupMobile = document.getElementById("signup-mobile");
        const loginMobile = document.getElementById("login-mobile");
        const dashboardMobile = document.getElementById("dashboard-mobile");

        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                masukHeader.style.display = "none";
                daftarHeader.style.display = "none";
                dashboardHeader.style.display = "flex";
                signupMobile.style.display = "none";
                loginMobile.style.display = "none";
                dashboardMobile.style.display = "flex";
            } else {
                masukHeader.style.display = "flex";
                daftarHeader.style.display = "flex";
                dashboardHeader.style.display = "none";
                signupMobile.style.display = "flex";
                loginMobile.style.display = "flex";
                dashboardMobile.style.display = "none";
            }
        })

        const handleMasukHeader = () => {
          window.location = "/login/"
        }

        const handleDaftarHeader = () => {
          window.location = "/signup/"
        }

        const handleDashboardHeader = () => {
          window.location = "/dashboard/"
        }

        const handleLoginMobile = () => {
          window.location = "/login/"
        }

        const handleSignupMobile = () => {
          window.location = "/signup/"
        }

        const handleDashboardMobile = () => {
          window.location = "/dashboard/"
        }

        masukHeader.addEventListener('click', handleMasukHeader)
        daftarHeader.addEventListener('click', handleDaftarHeader)
        dashboardHeader.addEventListener('click', handleDashboardHeader)
        loginMobile.addEventListener('click', handleLoginMobile)
        signupMobile.addEventListener('click', handleSignupMobile)
        dashboardMobile.addEventListener('click', handleDashboardMobile)

        return () => {
            unsubscribe()
            masukHeader.removeEventListener('click', handleMasukHeader)
            daftarHeader.removeEventListener('click', handleDaftarHeader)
            dashboardHeader.removeEventListener('click', handleDashboardHeader)
            loginMobile.removeEventListener('click', handleLoginMobile)
            signupMobile.removeEventListener('click', handleSignupMobile)
            dashboardMobile.removeEventListener('click', handleDashboardMobile)
        }
    }, [])

    return(
        <header>
        <div className="header-wrap flex-row center">
          <img src={Images.logo} alt="Logo TokoOnlinemu" id="photo-profile-toko" width="35px" height="35px" />
          <div className="flex-column">
            <p className="grey-text m700" id="title-text">toko<span className="pink-text">online</span>mu.</p>
            <p className="m400" id="slogan-text">Onlinekan Tokomu Bersama Kami</p>
          </div>
          <div className="m400 flex-row" id="navigation-desktop">
            <a href="#" className="flex-row center">
              <img src={Images.home} alt="Home Icon" width="20px" height="20px" />
              <p>Beranda</p>
            </a>
            <a href="#about-us" className="flex-row center">
              <img src={Images.aboutUs} alt="Feature Icon" width="20px" height="20px" />
              <p>Tentang Kami</p>
            </a>    
            <a href="#features" className="flex-row center">
              <img src={Images.feature} alt="Feature Icon" width="20px" height="20px" />
              <p>Fitur</p>
            </a>
            <a href="#contact-us" className="flex-row center">
              <img src={Images.cell} alt="Cell Icon" width="20px" height="20px" />
              <p>Hubungi Kami</p>
            </a>
            <a href="#founders" className="flex-row center">
              <img src={Images.user} alt="User Icon" width="20px" height="20px" />
              <p>Founder</p>
            </a>
          </div>
          <div className="nav-wrap flex-row center-right m400">
            <button className="nav-button" id="nav-button">
              <img className="center flex-row" src={Images.menu} alt="Menu icon" width="25px" height="25px" />
            </button>
            <div className="flex-row" id="button-login-header">
              <button className="header-button flex-row center pointer" style={{marginRight: '5px'}} id="daftar-header">
                <img src={Images.register} alt="Register Icon" style={{marginRight: '5px'}} width="24px" />
                <p className="m500">Daftar</p>
              </button>
              <button className="header-button flex-row center pointer" id="masuk-header">
                <img src={Images.login} alt="Register Icon" style={{marginRight: '5px'}} width="20px" />
                <p className="m500">Masuk</p>
              </button>
              <button className="header-button flex-row center pointer" id="dashboard-header" style={{display: 'none'}}>
                <img src={Images.dashboard} alt="Register Icon" style={{marginRight: '5px'}} width="24px" />
                <p className="m500">Dashboard</p>
              </button>
            </div>
          </div>
        </div>
        <div className="center m400" id="navigation-mobile">
          <a href="#" className="flex-row center">
            <img src={Images.home} alt="Home Icon" width="20px" height="20px" />
            <p>Beranda</p>
          </a>
          <a href="#about-us" className="flex-row center">
            <img src={Images.aboutUs} alt="Feature Icon" width="20px" height="20px" />
            <p>Tentang Kami</p>
          </a>  
          <a href="#features" className="flex-row center">
            <img src={Images.feature} alt="Feature Icon" width="20px" height="20px" />
            <p>Fitur</p>
          </a>
          <a href="#contact-us" className="flex-row center">
            <img src={Images.cell} alt="Cell Icon" width="20px" height="20px" />
            <p>Hubungi Kami</p>
          </a>
          <a href="#founders" className="flex-row center">
            <img src={Images.user} alt="User Icon" width="20px" height="20px" />
            <p>Founder</p>
          </a>
          <a href="./signup/" className="flex-row center" id="signup-mobile">
            <img src={Images.register} alt="Register Icon" width="24px" height="24px" />
            <p>Daftar</p>
          </a>
          <a href="./login/" className="flex-row center" id="login-mobile">
            <img src={Images.login} alt="User Icon" width="24px" height="24px" />
            <p>Masuk</p>
          </a>
          <a href="./dashboard/" className="flex-row center" id="dashboard-mobile" style={{display: 'none'}}>
            <img src={Images.dashboard} alt="User Icon" width="24px" height="24px" />
            <p>Dashboard</p>
          </a>
        </div>
      </header>
    )
}

export default Header