import React from 'react'
import '../App.css'
import Images from './Images'

const DashboardHeader = () => {
    return (
        <header>
        <div className="header-wrap flex-row center">
          <img src={Images.logo} alt="Logo TokoOnlinemu" width="35px" height="35px" />
          <div className="flex-column">
            <p className="grey-text m700" id="title-text">toko<span className="pink-text">online</span>mu.</p>
            <p className="m400" id="slogan-text">Onlinekan Tokomu Bersama Kami</p>
          </div>
          <div className="m400 flex-row" id="navigation-desktop">
            <a href="/" className="flex-row center">
              <img src={Images.home} alt="Home Icon" width="20px" height="20px" />
              <p>Beranda</p>
            </a>
            <a href="/dashboard/" className="flex-row center">
              <img src={Images.dashboard} alt="Home Icon" width="20px" height="20px" />
              <p>Dashboard</p>
            </a>
            <a href="/creative-breafing/" className="flex-row center">
              <img src={Images.previewBlack} alt="Home Icon" width="20px" height="20px" />
              <p>Creative Breafing</p>
            </a>
          </div>
          <div className="nav-wrap flex-row center-right m400">
            <button className="nav-button" id="nav-button">
              <img className="center flex-row" src={Images.menu} alt="Menu icon" width="25px" height="25px" />
            </button>
            <div className="flex-row" id="button-login-header">
              <button className="header-button flex-row center pointer" id="keluar-header">
                <img src={Images.logout} alt="Register Icon" style={{marginRight: '5px'}} width="24px" />
                <p className="m500">Keluar</p>
              </button>
            </div>
          </div>
        </div>
        <div className="center m400" id="navigation-mobile">
          <a href="/" className="flex-row center">
            <img src={Images.home} alt="Home Icon" width="20px" height="20px" />
            <p style={{minWidth: '140px'}}>Beranda</p>
          </a>
          <a href="/dashboard/" className="flex-row center">
            <img src={Images.dashboard} alt="Home Icon" width="20px" height="20px" />
            <p style={{minWidth: '140px'}}>Dashboard</p>
          </a>
          <a href="/creative-breafing/" className="flex-row center">
            <img src={Images.previewBlack} alt="Home Icon" width="20px" height="20px" />
            <p style={{minWidth: '140px'}}>Creative Breafing</p>
          </a>
          <button className="m400 flex-row center button-navigation-mobile" id="logout-mobile">
            <img src={Images.logoutMobile} alt="Logout Icon" style={{marginRight: '10px'}} width="20px" />
            <p style={{minWidth: '140px'}}>Keluar</p>
          </button>
        </div>
      </header>
    )
}

export default DashboardHeader