import aboutUs from '../assets/images/about-us.png';
import add from '../assets/images/add.png';
import arrowUp from '../assets/images/arrowup.png';
import bigScreenPhone from '../assets/images/bigscreenphone.png';
import buyWhite from '../assets/images/buy-white.png';
import buy from '../assets/images/buy.png';
import callService from '../assets/images/call-service.png';
import call from '../assets/images/call.png';
import cell from '../assets/images/cell.png';
import collaboration from '../assets/images/collaboration.png';
import dashboardWhite from '../assets/images/dashboard-white.png';
import dashboard from '../assets/images/dashboard.png';
import email from '../assets/images/email.png';
import facebookTemplate from '../assets/images/facebook-template.png';
import facebook from '../assets/images/facebook.png';
import favicon from '../assets/images/favicon.ico';
import feature from '../assets/images/feature.png';
import gmail from '../assets/images/gmail.png';
import google from '../assets/images/google.png';
import home from '../assets/images/home.png';
import instagramDashboard from '../assets/images/instagram-dashboard.png';
import instagramWhite from '../assets/images/instagram-white.png';
import instagram from '../assets/images/instagram.png';
import jumbotron from '../assets/images/jumbotron.png';
import login from '../assets/images/login.png';
import logo from '../assets/images/logo.png';
import logoutMobile from '../assets/images/logout-mobile.png';
import logout from '../assets/images/logout.png';
import menu from '../assets/images/menu.png';
import photoProfile from '../assets/images/photo-profile.png';
import picture from '../assets/images/picture.png';
import previewBlack from '../assets/images/preview-black.png';
import preview from '../assets/images/preview.png';
import price from '../assets/images/price.png';
import purchase from '../assets/images/purchase.png';
import registerWhite from '../assets/images/register-white.png';
import register from '../assets/images/register.png';
import save from '../assets/images/save.png';
import store from '../assets/images/store.png';
import study from '../assets/images/study.png';
import success from '../assets/images/success.png';
import sylvia from '../assets/images/sylvia.jpg';
import tableOfContent from '../assets/images/tableofcontent.png';
import thumbnail from '../assets/images/thumbnail.png';
import twitter from '../assets/images/twitter.png';
import twitterx from '../assets/images/twitterx.png';
import url from '../assets/images/url.png';
import user from '../assets/images/user.png';
import userProfilePhoneScreen from '../assets/images/userprofilephonescreen.png';
import warning from '../assets/images/warning.png';
import whatsappDashboard from '../assets/images/whatsapp-dashboard.png';
import whatsappWhite from '../assets/images/whatsapp-white.png';
import whatsapp from '../assets/images/whatsapp.png';
import youtube from '../assets/images/youtube.png';
import zulfachrie from '../assets/images/zulfachrie.jpg';

const Images = {
  aboutUs,
  add,
  arrowUp,
  bigScreenPhone,
  buyWhite,
  buy,
  callService,
  call,
  cell,
  collaboration,
  dashboardWhite,
  dashboard,
  email,
  facebookTemplate,
  facebook,
  favicon,
  feature,
  gmail,
  google,
  home,
  instagramDashboard,
  instagramWhite,
  instagram,
  jumbotron,
  login,
  logo,
  logoutMobile,
  logout,
  menu,
  photoProfile,
 picture,
  previewBlack,
  preview,
  price,
  purchase,
  registerWhite,
  register,
  save,
  store,
  study,
  success,
  sylvia,
  tableOfContent,
  thumbnail,
  twitter,
  twitterx,
  url,
  user,
  userProfilePhoneScreen,
  warning,
  whatsappDashboard,
  whatsappWhite,
  whatsapp,
  youtube,
  zulfachrie,
};

export default Images;